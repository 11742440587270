<template>
  <v-container class="pa-0">
    <v-col cols="12" class="mt-1 pt-1">
      <v-row
        :class="{'m-side justify-center ml-auto mr-auto align-center': $vuetify.breakpoint.smAndDown}" 
      >
        <div>
          <v-hover v-slot="{ hover }">
            <v-card
              class="a-img card-box mr-6 mt-4"
              color="lighten-4"
              width="320"
            >
              <v-img
                :aspect-ratio="4/3"
                src="@/assets/images/store_a.jpg"
                class="card-box"
              >
                <v-col 
                  class="store-img-text pl-4 font-weight-bold text-caption"
                >(본사직영) 아크키친 A지점
                </v-col>
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-caption black--text pl-4 pr-2 pt-2 align-start pb-4"
                    style="height: 100%;"
                  >
                    <div class="card-text ma-auto">
                      <v-col class="pa-0 d-flex align-center font-weight-bold">전라북도 전주시
                        <div class="ml-auto">
                          <v-btn 
                            class="text-caption font-weight-bold more-box"
                            color="primary" 
                            elevation="0"
                            @click="navigateTo('/store-infomation')" 
                          >자세히 보기
                          </v-btn>  
                        </div>   
                      </v-col>
                      <v-col class="pa-0 mb-2 d-flex align-center font-weight-bold">효자로 161, 204호
                      </v-col>
                        
                      <v-row class="pa-0 pl-2 mt-1 mb-1">
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon
                            color="primary-blue"
                            small
                          >
                            mdi-map-marker
                          </v-icon>
                          <span class="text-caption pl-2">도청인근</span>
                        </v-col>
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-thumb-up 
                          </v-icon>
                          <span class="text-caption pl-2">1등급
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0 pl-2 mt-1 mb-1">
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-store-clock
                          </v-icon>
                          <span class="text-caption pl-2">연중무휴·24시간</span>
                        </v-col>
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-parking 
                          </v-icon>
                          <span class="text-caption pl-2">주차장 넓음
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0 pl-2 mt-1 mb-1">
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-store
                          </v-icon>
                          <span class="text-caption pl-2">11개호실</span>
                        </v-col>
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-cash 
                          </v-icon>
                          <span class="text-caption pl-2">월 35만원부터~
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0 pl-2 mt-1 mb-1">
                        <v-col cols="12" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-plus-circle
                          </v-icon>
                          <span class="text-caption pl-2">중앙공조, 공용인터넷, 공용cctv, 개별전기···</span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0 mb-2">
                        <v-col cols="12" class="pa-0 d-flex justify-center align-center">
                          
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-card>
          </v-hover>
        </div>
        <div class="mt-4 mr-6">
          <v-hover v-slot="{ hover }">
            <v-card
              class="a-img "
              color="lighten-4"
              width="320"
              
            >
              <v-img
                :aspect-ratio="4/3"
                src="@/assets/images/store_b.jpg"
                class="card-box"
              >
                <v-col 
                  class="store-img-text pl-4 font-weight-bold text-caption"
                >(본사직영) 아크키친 B지점
                </v-col>
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-caption black--text pl-4 pr-2 pt-2 align-start pb-4"
                    style="height: 100%;"
                  >
                    <div class="card-text ma-auto">
                      <v-col class="pa-0 d-flex align-center font-weight-bold">전라북도 전주시
                        <div class="ml-auto">
                          <v-btn 
                            class="text-caption font-weight-bold more-box"
                            color="primary" 
                            elevation="0"
                            @click="navigateTo('/store-infomation')"
                          >자세히 보기
                          </v-btn>  
                        </div> 
                      </v-col>
                      <v-col class="pa-0 mb-2 d-flex align-center font-weight-bold">효자로 161, 206호</v-col>
                      <v-row class="pa-0 pl-2 mt-1 mb-1">
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-map-marker
                          </v-icon>
                          <span class="text-caption pl-2">도청인근</span>
                        </v-col>
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-thumb-up 
                          </v-icon>
                          <span class="text-caption pl-2">1등급
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0 pl-2 mt-1 mb-1">
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-store-clock
                          </v-icon>
                          <span class="text-caption pl-2">연중무휴·24시간</span>
                        </v-col>
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-parking 
                          </v-icon>
                          <span class="text-caption pl-2">주차장 넓음
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0 pl-2 mt-1 mb-1">
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-store
                          </v-icon>
                          <span class="text-caption pl-2">9개호실</span>
                        </v-col>
                        <v-col cols="6" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-cash 
                          </v-icon>
                          <span class="text-caption pl-2">월 33만원부터~
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0 pl-2 mt-1 mb-1">
                        <v-col cols="12" class="pa-0 d-flex align-center">
                          <v-icon 
                            color="primary-blue"
                            small
                          >
                            mdi-plus-circle
                          </v-icon>
                          <span class="text-caption pl-2">중앙공조, 공용인터넷, 공용cctv, 개별전기···</span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'MainStoreInfoSection', 
  components: {
  },
  mounted(){

  },
  computed: {

  },
  data() {
    return {

    }
  },
  methods: {
    navigateTo(url) { 
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.contents-box {
  max-width: 1000px !important;
}
.store-img-text {
  width: 100%;
  background-color: white;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
  background-color: #f4f7fa;
}
.m-side {
  width: 320px;
}
.round_box {
  background-color: white;
  border-radius: 8px;
}
.a-img {
  width: 140px;
}
.card-box {
  height: 180px !important;
}
.more-box {
  width: 90px !important;
  height: 24px !important;
}
.card-text {
  width: 260px !important;
  height: 140px !important
}
</style>