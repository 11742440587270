<template>
  <v-container fill-height fluid justify-center 
    class="bg-image pa-0"
    :class="{'bg-m': $vuetify.breakpoint.smAndDown}"
    >
      <div 
        class="wrap-box"
        :class="{'wrap-box-m': $vuetify.breakpoint.smAndDown}"
      >
        <div 
          class="text-box"
          :class="{'text-box-m': $vuetify.breakpoint.smAndDown}"
        >
          <v-col class="pa-0 d-flex justify-start align-center mt-md-0 mt-16 text-md-h5 font-weight-black">
            <div class="mr-md-2">'</div>
            <v-img 
              src="@/assets/logo/logo_text.png" 
              :max-height="$vuetify.breakpoint.mdAndUp ? 32 : 21" 
              :max-width="$vuetify.breakpoint.mdAndUp ? 269.7161 : 177.0012"
            >
            </v-img>
            <div class="ml-md-2">'은</div>
          </v-col>
          <div class="mt-md-4 text-caption text-md-h6 font-weight-bold"
            :class="{'mt-1 text-start' : $vuetify.breakpoint.smAndDown}"
          >
            <div>전라북도 최초 최대규모, 최적 요식업 전 분야 1등급(상권정보시스템)상권에서</div>
            <div class="mt-1">여러 요식업자분들의 성공적이며 안정적인 창업을 돕습니다.</div>
          </div>
          <div class="mt-md-6 justify-start">
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 140"
              :height="$vuetify.breakpoint.mdAndUp ? 36 : 36"
              outlined
              color="primary-red"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto"
              :class="{'mt-2' : $vuetify.breakpoint.smAndDown}"
              @click="navigateTo('/entering')"
            >입점신청
            </v-btn>
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 140"
              :height="$vuetify.breakpoint.mdAndUp ? 36 : 36"
              outlined
              color="primary-blue"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto ml-4"
              :class="{'mt-2' : $vuetify.breakpoint.smAndDown}"
              @click="navigateTo('/franchise')"
            >프랜차이즈 문의
            </v-btn>
          </div>
          <div 
          class="mt-md-4 mt-2 card-box"
        >
          <StoreInfoCard v-if="$vuetify.breakpoint.mdAndUp"/>
          <MobileStoreInfoCard v-if="$vuetify.breakpoint.smAndDown"/>   
        </div>
        </div>
      </div>
    <!-- <v-row class="contents-box ma-0 pa-0 d-flex">
      <div class="ma-0 pa-0">
        <div 
          class="ma-0 text-box"
          :class="{'ma-0 text-box-m': $vuetify.breakpoint.smAndDown}"
        >
          <div 
          class="text-h6 text-md-h4 font-weight-black"
          :class="{'text-center m-side justify-center ml-auto mr-auto': $vuetify.breakpoint.smAndDown}"
          >
            <v-col class="pa-0 d-flex justify-start align-center mt-md-0 mt-16">
                  <div>'</div>
                  <v-img 
                    src="@/assets/logo/logo_text.png" 
                    :max-height="$vuetify.breakpoint.mdAndUp ? 32 : 21" 
                    :max-width="$vuetify.breakpoint.mdAndUp ? 269.7161 : 177.0012"
                  >
                  </v-img>
                  <div>'은</div>
                </v-col>
            <div class="sub-text mt-md-4 text-caption text-md-h6 font-weight-bold"
            :class="{'mt-1 text-start' : $vuetify.breakpoint.smAndDown}">
              <div>전라북도 최초 최대규모, 최적 요식업 전 분야 1등급(상권정보시스템)상권에서</div>
              <div class="mt-1">여러 요식업자분들의 성공적이며 안정적인 창업을 돕습니다.</div>
            </div>
            <div class="mt-md-6 justify-start">
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 140"
              :height="$vuetify.breakpoint.mdAndUp ? 36 : 36"
              outlined
              color="primary-red"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto"
              :class="{'mt-2' : $vuetify.breakpoint.smAndDown}"
              @click="navigateTo('/entering')"
            >입점신청
            </v-btn>
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 140"
              :height="$vuetify.breakpoint.mdAndUp ? 36 : 36"
              outlined
              color="primary-blue"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto ml-4"
              :class="{'mt-2' : $vuetify.breakpoint.smAndDown}"
              @click="() => $router.push('/franchise')"
            >프랜차이즈 문의
            </v-btn>
          </div>
          </div>
        </div>
        <div 
          class="mt-md-4 mt-2 card-box"
        >
          <StoreInfoCard v-if="$vuetify.breakpoint.mdAndUp"/>
          <MobileStoreInfoCard v-if="$vuetify.breakpoint.smAndDown"/>   
        </div>
      </div>
    </v-row> -->
  </v-container>
</template>
<script>
import StoreInfoCard from '../components/MainStoreInfoMore/StoreInfoCard.vue'
import MobileStoreInfoCard from '../components/MainStoreInfoMore/MobileStoreInfoCard.vue'

export default {
  name: 'MainStoreInfoSection', 
  components: {
    StoreInfoCard,
    MobileStoreInfoCard,
  },
  mounted(){

  },
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
    navigateTo(link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-image {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-color: #F2F7FC;
}
.bg-m {
  width: 100%;
  height: 100vh;
  background-image: url('../../../../assets/images/m_bg.png');
  background-size: cover;
  background-position: center; 
}
// .contents-box {
//   max-width: 1000px !important;
// }
// .store-img-text {
//   width: 100%;
//   background-color: white;
// }
// .v-card--reveal {
//   align-items: center;
//   bottom: 0;
//   justify-content: center;
//   opacity: 1;
//   position: absolute;
//   width: 100%;
//   background-color: #f4f7fa;
// }

// .round_box {
//   background-color: white;
//   border-radius: 8px;
// }
// .text-box {
//   width: 1000px !important;
//   margin-top: 120px !important;
// }
// .text-box-m {
//   widows: 380px !important;
// }
// .card-box {
//   width: 380px !important;
// }
.wrap-box {
  width: 1000px !important;
}
.wrap-box-m {
  width: 320px !important;
}
.round_box {
  background-color: white;
  border-radius: 8px;
}
</style>