<template>
  <v-container class="pa-0">
    <div class="wrap-box">
      <div class="m-side d-flex mt-10">
        <AStore/>
        <BStore class="margin-box"/>
        <!-- <v-col 
          cols="2" 
          class="d-flex justify-center align-center cursor"
          :class="{'pa-0 mt-8': $vuetify.breakpoint.smAndDown}"
          @click="navigateTo('/franchise')"
        >
          <v-icon large color="blue darken-2"> mdi-plus-circle </v-icon>
        </v-col> -->
      </div>
    </div>
  </v-container>
</template>

<script>
import AStore from '../StoreCardBox/AStore.vue'
import BStore from '../StoreCardBox/BStore.vue'

export default {
  name: 'MainStoreInfoSection', 
  components: {
    AStore,
    BStore,
  },
  mounted(){

  },
  computed: {

  },
  data() {
    return {

    }
  },
  methods: {
    navigateTo(url) { 
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.m-side {
  width: 300px;
}
.round_box {
  background-color: white;
  border-radius: 8px;
}
.wrap-box {
  width: 380px !important;
}
.img-box {
  width: 300px;
}
.img-box2 {
  width: 300px;
  right: 0 !important;
}
.margin-box {
  margin-left: 120px;
}
</style>